<template>
  <div>
    <b-card>
      <template #header>
        <b-button variant="primary" size="sm" class="float-right" v-b-modal.modal-channel-access>
          Assign User <BIconPlus/>
        </b-button>
        <b-modal ref="modalChannelAccess" id="modal-channel-access" title="Available users" hide-footer>
          <ChannelAccessModal :channelUsersIds="channelUsersIds" :channel="channel" @on-user-assign="getChannelUsers()"/>
        </b-modal>
        <h6 class="mb-0">Channel access</h6>
      </template>
      <b-card-text>
        <div class="content">
          <b-table v-if="!users.isLoading" responsive hover :items="users.records" :fields="users.fields" empty-text="no users" show-empty>
            <template #cell(name)="data">
              <div class="text-left">
                {{data.item.firstName}} {{data.item.lastName}} ({{data.item.email}})
              </div>
            </template>
            <template #cell(options)="data">
              <div class="text-right">
                <b-link @click="unasignUser(data.item.id)" class="text-danger"><BIconX font-scale="1.5"/></b-link>
              </div>
            </template>
          </b-table>
          <b-spinner v-else label="Loading..." small></b-spinner>
        </div>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import ChannelAccessModal from './../../../components/Channel.Access';
import {BIconPlus, BIconX} from 'bootstrap-vue';
export default {
    props: ['channel'],
    components: {
      ChannelAccessModal,
      BIconPlus,BIconX
    },
    data(){
        return {
          channelUsersIds: [],
          users: {
            isLoading: false,
            records: [],
            fields: [],
            total: 0,
            pagination: {
              currentPageNo: 1
            }
          }
        };
    },
    methods:{
      getFields(){
        return [
          {key: 'name',label: "Name"},
          {key: 'options',label: ""},
        ];
      },
      unasignUser(userId){
        return this.$api.delete(`user/${userId}/channel/${this.channel.id}`).then(() => {
          this.getChannelUsers();
        });
      },
      getChannelUsers(){
        this.users.isLoading = true;
        return this.$api.get(`channel/${this.channel.id}/users`,{params: {perPage: 100}}).then(res => {
          this.users.isLoading = false;
          let {records, pagination, total} = res.data;
          this.users.records = records;
          this.users.pagination = pagination;
          this.users.total = total;
          this.users.fields = this.getFields();
          this.channelUsersIds = records.map(item => item.id);
        }).catch(() => {
          this.users.isLoading = false;
          console.log('cannot fetch channel users')
        });
      }
    },

    mounted(){
      this.$emit('set-channel',{activeSidebar: "manageUsers"});
      this.getChannelUsers();
    }
};
</script>
