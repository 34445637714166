<template>
  <div class="table-custom">
    <b-table v-if="!users.isLoading" responsive hover :items="users.records" :fields="users.fields" empty-text="no users" show-empty>
      <template #cell(name)="data">
        <div class="text-left">
          {{data.item.firstName}} {{data.item.lastName}} ({{data.item.email}})
        </div>
      </template>
      <template #cell(options)="data">
        <div class="text-right">
          <b-link @click="assignUser(data.item.id)"><BIconPlus font-scale="1.5"/></b-link>
        </div>
      </template>
    </b-table>
    <b-spinner v-else label="Loading..." small></b-spinner>
  </div>
</template>

<script>
import {BIconPlus} from 'bootstrap-vue';
export default {
  props: ['channelUsersIds','channel'],
  components: {
    BIconPlus
  },
  data(){
      return {
        users: {
          isLoading: false,
          records: [],
          fields: [],
          total: 0,
          pagination: {
            currentPageNo: 1
          }
        }
      };
  },
  methods: {
    getFields(){
      return [
        {key: 'name',label: "Name"},
        {key: 'options',label: ""},
      ];
    },
    assignUser(userId){
      return this.$api.put(`user/${userId}/channel/${this.channel.id}`).then(() => {
        this.$emit('on-user-assign');
      });
    },
    getUsers(){
      this.users.isLoading = true;
      return this.$api.get('users',{params: {perPage: 100}}).then(res => {
        this.users.isLoading = false;
        let {records, pagination, total} = res.data;
        this.users.records = records;
        this.users.pagination = pagination;
        this.users.total = total;
        this.users.fields = this.getFields();
      }).catch(() => {
        this.users.isLoading = false;
        console.log('cannot fetch users')
      });
    }
  },
  mounted(){
    this.getUsers();
  }
};
</script>
